body {
    background-color: #f5f5f5;
    font-family: 'Lato', sans-serif;
}
button {
    margin-top: .5px;
    background-color: #091A2C;
    padding: 6px;
    padding-bottom: 6px;
    margin: 5px;
    border: 2px solid#091a2d;
    border-radius: 5px;
    color: #fff;;
    font-size: 16px;
  }
  input {
    border: none;
    width: 52%;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.5;
    padding: 5px;
    padding-bottom: 6px;
  }
  input:focus {
    outline-width: 0;
  }